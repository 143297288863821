import React from 'react'
import { truncate } from 'lib/utils'

import styles from './ProfileReview.module.css'

const ProfileReview = ({ review, expanded, numberOfCharsAllowed }) => {
  const { messageToProfile } = review

  return (
    <div className={styles.card}>
      {messageToProfile && (
        <p className={styles.text}>
          &quot;
          {expanded
            ? messageToProfile
            : truncate(messageToProfile, numberOfCharsAllowed)}
          &quot;
        </p>
      )}
    </div>
  )
}

export default ProfileReview
