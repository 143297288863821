import React from 'react'
import dayjs from 'lib/dayjs'

type VideoDurationProps = {
  duration: number
}

const VideoDuration: React.FC<VideoDurationProps> = ({ duration }) => {
  return <>{dayjs.utc(duration * 1000).format('m:ss')}</>
}

export default VideoDuration
