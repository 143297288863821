import React, { forwardRef, useRef, useState } from 'react'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import Image from 'next/image'

import PlayIcon from 'components/PlayIcon/PlayIcon'
import { IconReplay } from 'components/UI/Icons/IconReplay'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import MuteButton from 'components/VideoPlayerWithControls/MuteButton'

import styles from './VideoPlayerWithControls.module.css'

type PlayButtonProps = {
  onClick: () => void
  isPlaying: boolean
  isLoading: boolean
  style?: React.CSSProperties
  size: number
  hidePauseButtonWhenPlaying: boolean
  className: string
  customReplayIconStyles?: string
  isVideoEnded: boolean
  text: string | undefined
  pauseOnBottomRight: boolean
}

const PlayButton = ({
  onClick,
  isPlaying,
  isLoading,
  style,
  size,
  hidePauseButtonWhenPlaying,
  className,
  isVideoEnded,
  text,
  pauseOnBottomRight,
  customReplayIconStyles,
}: PlayButtonProps) => (
  <button
    className={cn(styles.playButton, {
      [styles.backgroundColor]: isPlaying,
    })}
    onClick={onClick}
    style={style}
    type="button"
    data-cy="playVideoButtonOnRequestCard"
  >
    <PlayIcon
      paused={isPlaying}
      loading={isLoading}
      size={size}
      hidePauseButtonWhenPlaying={hidePauseButtonWhenPlaying}
      className={className}
      isVideoEnded={isVideoEnded}
      icon={<IconReplay className={customReplayIconStyles} />}
      text={text}
      pauseOnBottomRight={pauseOnBottomRight}
    />
  </button>
)

type VideoPlayerWithControlsProps = {
  src: string
  horizontalVideo?: boolean
  width?: number
  height?: number
  poster: string | undefined
  showVolumeControl?: boolean
  showFullscreenControl?: boolean
  onPlay: () => void
  onPause: () => void
  playButtonSize?: number
  showPlayButton?: boolean
  gradientTop?: boolean
  gradientBottom?: boolean
  roundedCorners?: boolean
  unmuteOnPlay?: boolean
  customPlayIconStyles?: string
  customReplayIconStyles?: string
  children?: React.ReactNode
  fullScreenOnPlay?: boolean
  fullScreenOnAutoPlay?: boolean
  onFullscreenExit?: () => void
  autoPlay?: boolean
  onVideoEnd?: () => void
  isVideoEnded?: boolean
  text: string | undefined
  hidePauseButtonWhenPlaying?: boolean
  pauseOnBottomRight?: boolean
}

function VideoPlayerWithControls(
  {
    src,
    horizontalVideo = false,
    width = 0,
    height = 0,
    poster = undefined,
    showVolumeControl = false,
    showFullscreenControl = false,
    onPlay = () => null,
    onPause = () => null,
    playButtonSize = 32,
    showPlayButton = true,
    gradientTop = false,
    gradientBottom = false,
    roundedCorners = false,
    unmuteOnPlay = false,
    customPlayIconStyles,
    customReplayIconStyles,
    children = undefined,
    fullScreenOnPlay = false,
    fullScreenOnAutoPlay = false,
    onFullscreenExit = () => null,
    autoPlay = false,
    onVideoEnd = () => null,
    isVideoEnded = false,
    text = undefined,
    hidePauseButtonWhenPlaying = true,
    pauseOnBottomRight = false,
  }: VideoPlayerWithControlsProps,
  ref: React.Ref<{ [key: string]: () => void } | null>,
) {
  const newVideoPlayerRef = useRef()
  const videoPlayerRef = ref || newVideoPlayerRef
  const [isReady, setIsReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <div
      className={cn(styles.container, {
        [styles.gradientTop]: gradientTop,
        [styles.gradientBottom]: gradientBottom,
        [styles.roundedCorners]: roundedCorners,
        [styles.containerHorizontal]: horizontalVideo,
      })}
    >
      <div className={cn(styles.mediaWrapper)}>
        <div
          className={styles.playerWrapper}
          style={{ display: isReady ? 'block' : 'none' }}
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <VideoPlayer
              ref={videoPlayerRef}
              onPlay={() => {
                setIsPlaying(true)
                onPlay()
              }}
              onPause={() => {
                setIsPlaying(false)
                onPause()
              }}
              onReady={() => setIsReady(true)}
              src={src}
              poster={poster}
              unmuteOnPlay={unmuteOnPlay}
              loop={false}
              autoPlay={autoPlay}
              fullScreenOnAutoPlay={fullScreenOnAutoPlay}
              fullScreenOnPlay={fullScreenOnPlay}
              onFullscreenExit={onFullscreenExit}
              onVideoEnd={onVideoEnd}
            />
          }
        </div>
        <div className={styles.posterWrapper}>
          {poster && (
            <Image
              src={poster}
              key={poster}
              alt="Video"
              className={cn(styles.poster, {
                [styles.posterPlaying]: isPlaying,
              })}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style={{ display: isReady ? 'none' : 'block' }}
              layout={
                horizontalVideo && width && height ? 'responsive' : 'fill'
              }
              width={horizontalVideo && width}
              height={horizontalVideo && height}
              unoptimized
            />
          )}

          {children}
        </div>
      </div>
      {showPlayButton && (
        <PlayButton
          size={playButtonSize}
          isPlaying={isPlaying}
          isLoading={isPlaying && !isReady}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={(e: Event) => videoPlayerRef.current?.playPause(e)}
          hidePauseButtonWhenPlaying={hidePauseButtonWhenPlaying}
          className={customPlayIconStyles}
          customReplayIconStyles={customReplayIconStyles}
          isVideoEnded={isVideoEnded}
          text={text}
          pauseOnBottomRight={pauseOnBottomRight}
        />
      )}

      <div className={styles.controls}>
        {showVolumeControl && isPlaying && (
          <MuteButton
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={(e: Event) => videoPlayerRef.current?.toggleMute(e)}
          />
        )}
        {showFullscreenControl && isPlaying && (
          <button
            className={styles.muteButton}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={videoPlayerRef.current?.showFullscreen}
            type="button"
          >
            <FontAwesomeIcon icon={faDesktop} color="#fff" />
          </button>
        )}
      </div>
    </div>
  )
}

export default forwardRef(VideoPlayerWithControls)
