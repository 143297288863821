import React, { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'

import Heading from 'components/Heading/Heading'
import Link from 'components/Link/Link'
import ProfileReview from 'components/ProfileReview/ProfileReview'
import Emoji from 'components/UI/Emoji/Emoji'
import { IconStarFull as IconStar } from 'components/UI/Icons'
import VideoDuration from 'components/VideoDuration/VideoDuration'
import VideoPlayerWithControls from 'components/VideoPlayerWithControls/VideoPlayerWithControls'

import styles from './VideoRequestCard.module.css'

const VideoRequestCard = ({ request, loading, showTextContent = true }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [expanded, setExpanded] = useState(null)
  const { t } = useTranslation()

  if (loading) return <div>Loading..</div>
  if (!request && !loading) return <div>Error..</div>

  const { publicProfile = {}, video = {}, review, requestOccasion } = request
  const { slug, name, title, averageReviewRating } = publicProfile
  const { url, thumbnailUrl, duration } = video

  const numberOfCharsAllowed = 70
  const isTruncated = review?.messageToProfile?.length > numberOfCharsAllowed

  return (
    <div className={styles.card} data-cy="videoRequestCard">
      <div className={styles.playerWrapper}>
        <VideoPlayerWithControls
          src={url}
          poster={thumbnailUrl}
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          roundedCorners
          gradientBottom
          showVolumeControl
          unmuteOnPlay={false}
          showPlayButton={!expanded}
          hidePauseButtonWhenPlaying={false}
          pauseOnBottomRight
        />
      </div>

      <div className={styles.aspect} />

      <>
        <div className={styles.occasionAndDuration}>
          {requestOccasion && requestOccasion.internalName !== 'other' && (
            <div
              className={styles.occasion}
              style={{
                backgroundColor:
                  requestOccasion?.color || 'hsla(0, 0%, 0%, 0.5)',
              }}
            >
              <Emoji emoji={requestOccasion.emoji} className={styles.emoji} />
              {requestOccasion.title}
            </div>
          )}
          {duration && videoPlaying && (
            <div className={styles.duration}>
              <VideoDuration duration={duration} />
            </div>
          )}
        </div>

        {(showTextContent || review) && (
          <main
            className={cn(styles.content, {
              [styles.contentExpanded]: expanded,
            })}
          >
            {showTextContent && !videoPlaying && (
              <>
                <div
                  className={cn(styles.details, {
                    [styles.detailsWithReview]: review,
                  })}
                >
                  {averageReviewRating && (
                    <div className={styles.ratingContainer}>
                      <IconStar className={styles.starIcon} height={13} />
                      <p className={styles.ratingNumber}>
                        {averageReviewRating.toFixed(1)}
                      </p>
                    </div>
                  )}
                  <Link href={`/profile/${slug}`}>
                    <a>
                      <Heading level="h5" color="light">
                        <h4>{name}</h4>
                      </Heading>
                    </a>
                  </Link>
                  {!review && <div className={styles.title}>{title}</div>}
                </div>
              </>
            )}
            {review && !videoPlaying && (
              <div className={styles.reviewContainer}>
                <ProfileReview
                  numberOfCharsAllowed={numberOfCharsAllowed}
                  review={review}
                  expanded={expanded}
                />
                <div
                  tabIndex={0}
                  role="button"
                  className={styles.reviewButton}
                  onClick={() => setExpanded(!expanded)}
                >
                  {isTruncated &&
                    (expanded ? (
                      <>
                        {t('VideoRequestCard.hide', 'Hide')}
                        <DownOutlined className={styles.reviewButtonIcon} />
                      </>
                    ) : (
                      <>
                        {t('VideoRequestCard.more', 'More')}
                        <UpOutlined className={styles.reviewButtonIcon} />
                      </>
                    ))}
                </div>
              </div>
            )}
          </main>
        )}
      </>
    </div>
  )
}

export default VideoRequestCard
