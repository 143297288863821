import React from 'react'
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGeneralContext } from 'contexts/GeneralContext'

import styles from './VideoPlayerWithControls.module.css'

export default function MuteButton({ onClick }) {
  const { isMuted } = useGeneralContext()

  return (
    <button
      className={styles.muteButton}
      onClick={(e) => onClick(e)}
      type="button"
    >
      <FontAwesomeIcon
        icon={!isMuted ? faVolumeUp : faVolumeMute}
        color="#fff"
      />
    </button>
  )
}
