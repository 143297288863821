import { gql, useQuery } from '@apollo/client'
import { currentMarketIdVar } from 'graphql/reactive'

export const GET_PUBLIC_VIDEO_REQUEST = gql`
  query GetPublicVideoRequest($marketId: ID!, $id: ID) {
    market(id: $marketId) {
      id
      request(id: $id) {
        id
        shortId
        status

        fromName
        toName
        instructions
        isGift

        createdAt

        video {
          id
          thumbnailUrl
          url(preset: VIDEO_DEFAULT)
          downloadUrl: url(preset: VIDEO_DOWNLOAD)

          duration
        }

        requestOccasion {
          id
          internalName
          title
          emoji
        }

        publicProfile {
          id
          name
          slug
          title
          description
        }
      }
    }
  }
`

export default function usePublicVideoRequest(id) {
  const marketId = currentMarketIdVar()

  const { loading, data = {}, error } = useQuery(GET_PUBLIC_VIDEO_REQUEST, {
    variables: { id, marketId },
  })

  const request = data.market && data.market.request

  return { request, loading, error }
}
