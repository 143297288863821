/* eslint-disable jsx-a11y/media-has-caption */
import React, { MutableRefObject, useEffect, useRef } from 'react'

const Video: React.FC<
  React.VideoHTMLAttributes<HTMLVideoElement> & {
    ref?: MutableRefObject<HTMLVideoElement>
  }
> = ({ ref, src, className, ...props }) => {
  const video = useRef(null)
  const hls = useRef(null)
  // TODO: Might make more sense to move this to the API
  const isHLSSrc = /.m3u8/.test(src)

  let videoSrc = src
  if (!isHLSSrc) videoSrc = `${src}#t=0.5`

  useEffect(() => {
    async function setupHLSIfNeeded() {
      // eslint-disable-next-line no-param-reassign
      if (ref) ref.current = video.current
      const videoNode = video.current

      if (videoNode && !isHLSSrc) videoNode.src = videoSrc
      if (videoNode && isHLSSrc) {
        if (videoNode.canPlayType('application/vnd.apple.mpegurl')) {
          // This will run in safari, where HLS is supported natively
          videoNode.src = videoSrc
        } else {
          const Hls = (await import('hls.js')).default
          if (Hls.isSupported()) {
            // This will run in all other modern browsers
            hls.current = new Hls()
            hls.current.attachMedia(videoNode)
            hls.current.loadSource(videoSrc)
          }
        }
      }
    }
    setupHLSIfNeeded()

    return () => {
      if (hls.current) {
        hls.current.destroy()
      }
    }
  }, [isHLSSrc, videoSrc, video, ref])

  return <video {...props} ref={video} className={className} src={videoSrc} />
}

export default Video
