import React from 'react'
import { logger } from 'lib/logger'

import VideoRequestCard from 'components/VideoRequestCard/VideoRequestCard'
import usePublicVideoRequest from 'hooks/usePublicVideoRequest'

export default function VideoRequestLoader({ id }) {
  const { loading, error, request } = usePublicVideoRequest(id)

  if (error) {
    logger.error(
      `[ContentLoad Error] Error loading content for PublicVideoRequest: ${id}`,
      error,
    )
    return null
  }

  return <VideoRequestCard request={request} loading={loading} />
}
